import 'twin.macro';

import React from 'react';
import { graphql } from 'gatsby';
import ContentBlocks from '../sanity/ContentBlocks';

import Layout from '../components/Layout';
import Seo from '../components/global/Seo';
import HomeHero from '../components/home/HomeHero';
import DealLayout from '../components/deals/DealLayout';

export const dealsQuery = graphql`
  query dealPageQuery {
    allSanityProduct {
      nodes {
        _id
        title
        slug {
          current
        }
        numberOfJets
        numberOfSeats
        price
        description
        image {
          asset {
            gatsbyImageData
          }
        }
        isFeatured
        body
        colors {
          title
          value
        }
        condition {
          _id
          title
          slug {
            current
          }
        }
      }
    }
    sanityDeal {
      _id
      seoTitle
      seoDescription
      seoImage {
        asset {
          url
        }
      }
      contentBlocks: _rawContentBlocks(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const DealsPage = ({ data }) => {
  const products = data.allSanityProduct;
  const deal = data.sanityDeal;

  return (
    <Layout>
      <Seo
        title={deal.seoTitle}
        description={deal.seoDescription}
        image={deal.seoImage ? deal.seoImage.asset.url : ''}
      />
      <HomeHero
        title={deal.seoTitle}
        description={deal.seoDescription}
        fluid={
          deal.seoImage && deal.seoImage.asset
            ? deal.seoImage.asset.gatsbyImageData
            : ''
        }
      />
      <DealLayout data={products.nodes} />
      {deal.contentBlocks &&
        deal.contentBlocks.map((block, idx) =>
          React.createElement(ContentBlocks(block._type), {
            idx,
            key: block._key,
            ...block,
          }),
        )}
    </Layout>
  );
};

export default DealsPage;
