import tw from 'twin.macro';
import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import Collapse from '../elements/Collapse';

const conditionQuery = graphql`
  query allSanityCondition {
    allSanityCondition(sort: { fields: _updatedAt }) {
      nodes {
        _id
        title
        slug {
          current
        }
      }
    }
    allSanityProduct(sort: { fields: _createdAt }) {
      nodes {
        _id
        price
        numberOfSeats
        numberOfJets
      }
    }
  }
`;

const numberOfSeats = [
  { id: 1, from: 0, to: 5, title: 'under 5' },
  { id: 2, from: 5, to: 6, title: 'From 5 - 6' },
  { id: 3, from: 7, to: 8, title: 'From 7 - 8 ' },
  { id: 4, from: 9, to: 10, title: 'From 9 - 10' },
  { id: 5, from: 11, to: 50, title: 'Over 10' },
];

const numberOfJets = [
  { id: 1, from: 0, to: 5, title: 'under 5' },
  { id: 2, from: 6, to: 10, title: 'From 6 - 10' },
  { id: 3, from: 11, to: 20, title: 'From 11 - 20 ' },
  { id: 4, from: 21, to: 30, title: 'From 21 - 30' },
  { id: 5, from: 31, to: 40, title: 'From 31 -40' },
  { id: 6, from: 41, to: 50, title: 'From 41 - 50' },
  { id: 7, from: 51, to: 600, title: 'Over 50' },
];

const priceRange = [
  { id: 1, fromPrice: 0, toPrice: 5000, title: 'Under £5000' },
  { id: 2, fromPrice: 5000, toPrice: 7500, title: '£5,000 - £7,500' },
  { id: 3, fromPrice: 7500, toPrice: 10000, title: '£7,500 - £10,000' },
  { id: 4, fromPrice: 10000, toPrice: 13000, title: '£10,000 - £13,000' },
  { id: 5, fromPrice: 13000, toPrice: 22000, title: '£13,000 - £22,000' },
  { id: 6, fromPrice: 22000, toPrice: 100000, title: 'Over £22,000' },
];

const Sidebar = ({
  onPriceChange,
  handleJetsChange,
  handleSeatsChange,
  selectedPriceRange,
  noOfJets,
  noOfSeats,
}) => {
  const data = useStaticQuery(conditionQuery);
  const conditions = data.allSanityCondition.nodes;
  return (
    <div>
      <p tw="py-10 font-bold">Filters</p>
      <Collapse title="Condition">
        <Link to="/deals">
          <p tw="py-1">All</p>
        </Link>
        {conditions &&
          conditions.map((item) => (
            <div
              key={item._id}
              tw="mt-3 font-medium hover:underline hover:text-green">
              <Link to={`/condition/${item.slug && item.slug.current}`}>
                {item.title}
              </Link>
            </div>
          ))}
      </Collapse>

      <Collapse title="Number of seats">
        <a
          onClick={() => {
            handleSeatsChange('');
          }}>
          <p css={[tw`py-1 `, !noOfSeats && tw`font-bold`]}>All</p>
        </a>
        {numberOfSeats.map((item) => {
          return (
            <a
              css={[
                tw`hover:underline`,
                noOfSeats &&
                noOfSeats.from === item.from &&
                noOfSeats.to === item.to
                  ? tw`font-bold hover:underline`
                  : tw``,
              ]}
              key={item.id}
              onClick={() =>
                handleSeatsChange({
                  from: item.from,
                  to: item.to,
                })
              }>
              <p tw="py-1">{item.title}</p>
            </a>
          );
        })}
      </Collapse>

      <Collapse title="Number of jets">
        <a
          onClick={() => {
            handleJetsChange('');
          }}>
          <p css={[tw`py-1 `, !noOfJets && tw`font-bold`]}>All</p>
        </a>
        {numberOfJets.map((item) => (
          <a
            key={item.id}
            css={[
              noOfJets && noOfJets.from === item.from && noOfJets.to === item.to
                ? tw`font-bold hover:underline`
                : tw`hover:underline`,
            ]}
            onClick={() =>
              handleJetsChange({
                from: item.from,
                to: item.to,
              })
            }>
            <p tw="py-1">{item.title}</p>
          </a>
        ))}
      </Collapse>

      <Collapse title="Price">
        {priceRange.map((item) => {
          return (
            <a
              key={item.id}
              css={[
                selectedPriceRange &&
                selectedPriceRange.fromPrice === item.fromPrice &&
                selectedPriceRange.toPrice === item.toPrice
                  ? tw`font-bold hover:underline`
                  : tw`hover:underline`,
              ]}
              onClick={() =>
                onPriceChange({
                  fromPrice: item.fromPrice,
                  toPrice: item.toPrice,
                })
              }>
              <p tw="py-1">{item.title}</p>
            </a>
          );
        })}
      </Collapse>
    </div>
  );
};

export default Sidebar;
