import 'twin.macro';

import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';

import ProductItem from '../product/ProductItem';
import { Container } from '../atoms';
import { EmptyState } from '../elements';
import Sidebar from './Sidebar';

const DealLayout = ({ data }) => {
  if (!data) return null;

  const [products, setProducts] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState(null);
  const [noOfJets, setNoOfJets] = useState(null);
  const [noOfSeats, setNoOfSeats] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (data) {
      setProducts(data);
    }
  });

  useEffect(() => {
    if (!selectedPriceRange && !noOfSeats && !noOfJets) {
      setFilteredProducts(products);
    } else if (selectedPriceRange || noOfSeats || noOfJets) {
      let filteredProductsData = products;
      if (selectedPriceRange) {
        filteredProductsData = filter(filteredProductsData, function (o) {
          const { price } = o;
          if (selectedPriceRange.toPrice) {
            return (
              price >= selectedPriceRange.fromPrice &&
              price <= selectedPriceRange.toPrice
            );
          }
          return price > selectedPriceRange.fromPrice;
        });
      }

      if (noOfJets) {
        filteredProductsData = filter(filteredProductsData, function (o) {
          const { numberOfJets } = o;
          return numberOfJets >= noOfJets.from && numberOfJets <= noOfJets.to;
        });
      }
      if (noOfSeats) {
        filteredProductsData = filter(filteredProductsData, function (o) {
          const { numberOfSeats } = o;
          return (
            numberOfSeats >= noOfSeats.from && numberOfSeats <= noOfSeats.to
          );
        });
      }

      setFilteredProducts(filteredProductsData);
    }
  }, [selectedPriceRange, noOfSeats, noOfJets, products]);

  return (
    <Container tw="bg-slate">
      <div tw="py-10">
        <div tw="flex flex-col md:flex-row items-center md:items-start md:justify-between">
          <div tw="md:px-6 py-4 mt-4 w-full md:w-1/4">
            <Sidebar
              onPriceChange={(val) => setSelectedPriceRange(val)}
              handleJetsChange={setNoOfJets}
              handleSeatsChange={setNoOfSeats}
              selectedPriceRange={selectedPriceRange}
              noOfJets={noOfJets}
              noOfSeats={noOfSeats}
            />
          </div>
          <div tw="w-full md:w-3/4">
            {data.length < 1 && (
              <div tw="text-center">
                <EmptyState title="No products found" />
              </div>
            )}
            <div tw="grid grid-cols-1 md:grid-cols-2 gap-8 2xl:grid-cols-3">
              {filteredProducts &&
                filteredProducts.map((item, i) => (
                  <ProductItem data={item} key={item._id || i + 1} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DealLayout;
